import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import i18n from '@shared/i18n/vue-i18n'

export default function useLogout(options = {}) {
  const store = useStore()
  const router = useRouter()

  /**
   * Redirect user to login.
   */
  function logout() {
    handleLogout(async () => {
      await router.push({ name: options.auth_route_name ?? 'login' })
    })
  }

  /**
   * Manually redirect user.
   *
   * @param url
   */
  function standaloneLogout(url) {
    handleLogout(async () => {
      await router.push({
        redirect: window.location.href = url,
      })
    })
  }

  /**
   * Logout user and run action on success!
   *
   * @param callback
   */
  function handleLogout(callback) {
    store.commit('loading/SET_TEXT', i18n.global.t('ttmt.auth.logout.logging_out'))
    store.commit('loading/ENABLE')
    store.dispatch('auth/signOutFromApi')
      .then(async () => callback())
      .finally(() => {
        store.commit('loading/RESET')
      })
  }

  return {
    logout,
    standaloneLogout,
  }
}
