import axios from 'axios'

// Common options for all axios request instances
const commonAxiosOptions = {
  withCredentials: true,
  baseURL: import.meta.env.NODE_ENV === 'test' ? '' : import.meta.env.VITE_API_BASE_URL,
  xsrfCookieName: `XSRF-TOKEN-${import.meta.env.VITE_APP_ENV}`,
}

// Base axios API request instance
export const apiAxios = axios.create({
  ...commonAxiosOptions,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'multipart/form-data',
  },
})

// Axios API request instance for blob types
// (ie: PDF files)
export const apiAxiosBlob = axios.create({
  ...commonAxiosOptions,
  responseType: 'blob',
})

// ---------- AUTH ----------

export const login = (payload) => apiAxios.post(
  '/auth/login',
  payload,
)

export const authenticateLogin = (payload) => apiAxios.post(
  '/auth/login-guest',
  payload,
)

export const logout = () => apiAxios.post(
  '/auth/logout',
)

export const register = (payload) => apiAxios.post(
  '/auth/register',
  payload,
)

export const sendPasswordResetLink = (payload) => apiAxios.post(
  '/auth/password-resets/send',
  payload,
)

export const resetPassword = (id, payload) => apiAxios.post(
  `/auth/password-resets/${id}/reset`,
  payload,
)

export const sanctumCsrfCookieUrl = `${import.meta.env.VITE_APP_BASE_URL}/sanctum/csrf-cookie`
export const sanctumCsrfCookie = () => apiAxios.get(
  sanctumCsrfCookieUrl,
)

export const fetchCurrentUser = (payload) => apiAxios.get(
  '/auth/user',
  { params: payload },
)

export const updateCurrentUser = (payload) => apiAxios.patch(
  '/auth/user',
  payload,
)

export const updateCurrentUserPrivacyPolicy = (payload) => apiAxios.post(
  '/auth/user/update-privacy-policy',
  payload,
)

export const updateCurrentUserTermsCondition = (payload) => apiAxios.post(
  '/auth/user/update-terms-condition',
  payload,
)

export const deleteUser = (payload) => apiAxios.patch(
  '/auth/user/delete-account',
  payload,
)

export const updateGeolocSetting = (payload) => apiAxios.patch(
  '/auth/geoloc-setting',
  payload,
)

export const updateNotificationSetting = (payload) => apiAxios.patch(
  '/auth/notification-setting',
  payload,
)

export const updatePrivacySetting = (payload) => apiAxios.patch(
  '/auth/privacy-setting',
  payload,
)

export const sendCredentialVerification = (payload) => apiAxios.post(
  '/auth/credential-verifications/send',
  payload,
)

export const broadcastingAuth = (payload) => apiAxios.post(
  '/broadcasting/auth',
  payload,
)

export const defaultAvatar = (payload) => apiAxios.post(
  '/auth/user/default-avatar',
  payload,
)

// ---------- ADVICES -------------

export const storeAdvice = (payload) => apiAxios.post(
  '/advices',
  payload,
)

export const updateAdvice = (id, payload) => apiAxios.patch(
  `/advices/${id}`,
  payload,
)

export const destroyAdvice = (id) => apiAxios.delete(
  `/advices/${id}`,
)

// ---------- EXCURSIONS ----------

export const fetchExcursions = (payload) => apiAxios.get(
  '/excursions',
  { params: payload },
)

export const fetchFavorites = (payload) => apiAxios.get(
  '/excursions/favorites',
  { params: payload },
)

export const storeExcursion = (payload) => apiAxios.post(
  '/excursions',
  payload,
)

export const fetchExcursion = (id, payload) => apiAxios.get(
  `/excursions/${id}`,
  { params: payload },
)

export const updateExcursion = (id, payload) => apiAxios.patch(
  `/excursions/${id}`,
  payload,
)

export const destroyExcursion = (id) => apiAxios.delete(
  `/excursions/${id}`,
)

export const shareExcursion = (id, payload) => apiAxios.post(
  `/excursions/${id}/share`,
  payload,
)

export const pdfExcursion = (id, payload) => apiAxiosBlob.get(
  `/excursions/${id}/pdf`,
  payload,
)

// ---------- POINTS OF INTEREST ----------

export const fetchPointsOfInterest = (payload) => apiAxios.get(
  '/points-of-interest',
  { params: payload },
)

export const storePointOfInterest = (payload) => apiAxios.post(
  '/points-of-interest',
  payload,
)

export const fetchPointOfInterest = (id) => apiAxios.get(
  `/points-of-interest/${id}`,
)

export const updatePointOfInterest = (id, payload) => apiAxios.patch(
  `/points-of-interest/${id}`,
  payload,
)

export const destroyPointOfInterest = (id) => apiAxios.delete(
  `/points-of-interest/${id}`,
)

export const sharePointOfInterest = (id, payload) => apiAxios.post(
  `/points-of-interest/${id}/share`,
  payload,
)

export const reportPointOfInterest = (id, payload) => apiAxios.post(
  `/points-of-interest/${id}/report`,
  payload,
)

export const claimPointOfInterest = (id, payload) => apiAxios.post(
  `/points-of-interest/${id}/claim`,
  payload,
)

export const disableSynchronizationPointOfInterest = (id) => apiAxios.post(
  `/points-of-interest/${id}/disable-synchronization`,
)

export const fetchOwnedPointsOfInterest = (payload) => apiAxios.get(
  '/points-of-interest/owned',
  { params: payload },
)

export const contestPointOfInterest = (id, payload) => apiAxios.post(
  `/points-of-interest/${id}/contest`,
  payload,
)

export const applyPointOfInterest = (id, payload) => apiAxios.post(
  `/points-of-interest/${id}/apply`,
  payload,
)

export const checkDuplicatesPointsOfInterest = (payload) => apiAxios.post(
  '/points-of-interest/check-duplicates',
  payload,
)

// ---------- EXCURSION POINT OF INTEREST ----------

export const storeExcursionPointOfInterest = (payload) => apiAxios.post(
  '/excursion-point-of-interest',
  payload,
)

// ---------- POINT OF INTEREST CATEGORIES ----------

export const fetchPointOfInterestCategories = (payload) => apiAxios.get(
  '/point-of-interest-categories',
  { params: payload },
)

// ---------- SHARES ----------

export const fetchShares = (payload) => apiAxios.get(
  '/shares',
  { params: payload },
)

export const institutionShares = (payload) => apiAxios.get(
  '/shares/institution',
  { params: payload },
)

export const previousUsersShares = (payload) => apiAxios.get(
  '/shares/previous-users',
  { params: payload },
)

// ---------- INVITATIONS ----------

export const fetchInvitations = (payload) => apiAxios.get(
  '/invitations',
  { params: payload },
)

export const inviteTraveller = (payload) => apiAxios.post(
  '/invite-traveller',
  payload,
)

export const inviteProfessional = (payload) => apiAxios.post(
  '/invite-professional',
  payload,
)

export const inviteEmployee = (payload) => apiAxios.post(
  '/invite-employee',
  payload,
)

// ---------- NOTIFICATIONS ----------

export const fetchNotifications = (payload) => apiAxios.get(
  '/notifications',
  { params: payload },
)

export const markNotificationsAsRead = (payload) => apiAxios.post(
  '/notifications/mark-as-read',
  payload,
)

// ---------- CONTACTS ----------

export const fetchContact = (id, payload) => apiAxios.get(
  `/users-relationships/${id}`,
  { params: payload },
)

export const fetchContacts = (payload) => apiAxios.get(
  '/users-relationships',
  { params: payload },
)

export const addContact = (payload) => apiAxios.post(
  '/users-relationships/add',
  payload,
)

export const approveContact = (id, payload) => apiAxios.patch(
  `/users-relationships/${id}/approve`,
  payload,
)

export const declineContact = (id, payload) => apiAxios.patch(
  `/users-relationships/${id}/decline`,
  payload,
)

export const blockContact = (id, payload) => apiAxios.patch(
  `/users-relationships/${id}/block`,
  payload,
)

export const unblockContact = (id, payload) => apiAxios.patch(
  `/users-relationships/${id}/unblock`,
  payload,
)

export const fetchPendingContacts = () => apiAxios.get(
  '/users-relationships/pending',
)

// ---------- USERS ----------

export const fetchUsers = (payload) => apiAxios.get(
  '/users',
  { params: payload },
)

export const fetchUser = (id, payload) => apiAxios.get(
  `/users/${id}`,
  { params: payload },
)

export const fetchButlers = (payload) => apiAxios.get(
  '/users/butlers',
  { params: payload },
)

export const fetchTravellers = (payload) => apiAxios.get(
  '/users/travellers',
  { params: payload },
)

export const fetchTouristOfficeEmployees = (payload) => apiAxios.get(
  '/users/tourist-office-employees',
  { params: payload },
)

export const sendUserLink = (id, payload) => apiAxios.post(
  `/users/${id}/link`,
  payload,
)

export const reportUser = (id, payload) => apiAxios.post(
  `/users/${id}/report`,
  payload,
)

export const fetchUsersInteractionButler = (payload) => apiAxios.get(
  '/users/interactions-butler',
  { params: payload },
)

export const fetchUsersInstitutionsButler = (payload) => apiAxios.get(
  '/users/institutions-butler',
  { params: payload },
)

export const checkExistingUser = (payload) => apiAxios.post(
  '/users/check-existing',
  payload,
)

// ---------- TRAVELLER FOLDERS ----------

export const fetchTravellerFolders = (payload) => apiAxios.get(
  '/traveller-folders',
  { params: payload },
)

export const fetchTravellerFolder = (id, payload) => apiAxios.get(
  `/traveller-folders/${id}`,
  { params: payload },
)

export const fetchLastButlerTravellerFolder = () => apiAxios.get(
  '/traveller-folders/butler-last',
)

export const fetchLastTravellerTravellerFolder = () => apiAxios.get(
  '/traveller-folders/traveller-last',
)

export const storeTravellerFolder = (payload) => apiAxios.post(
  '/traveller-folders',
  payload,
)

export const updateTravellerFolder = (id, payload) => apiAxios.patch(
  `/traveller-folders/${id}`,
  payload,
)

export const approveTravellerFolder = (id, payload) => apiAxios.patch(
  `/traveller-folders/${id}/approve`,
  payload,
)

export const archiveTravellerFolder = (id, payload) => apiAxios.patch(
  `/traveller-folders/${id}/archive`,
  payload,
)

export const thankTravellerFolder = (id, payload) => apiAxios.patch(
  `/traveller-folders/${id}/thank`,
  payload,
)

// ---------- STATS ----------

export const fetchStatInstitutions = () => apiAxios.get(
  '/stats/institutions',
)

export const fetchStatFolders = () => apiAxios.get(
  '/stats/folders',
)

export const fetchStatExcursions = () => apiAxios.get(
  '/stats/excursions',
)

export const fetchStatReservations = () => apiAxios.get(
  '/stats/reservations',
)

export const fetchStatEmployees = (payload) => apiAxios.get(
  '/stats/employees',
  { params: payload },
)

// ---------- INSTITUTIONS EMPLOYEES ----------

export const fetchInstitutionsEmployees = (payload) => apiAxios.get(
  '/institutions-employees',
  { params: payload },
)

export const cancelInstitutionsEmployees = (id, payload) => apiAxios.post(
  `/institutions-employees/${id}/cancel`,
  payload,
)

export const declineInstitutionsEmployees = (id, payload) => apiAxios.post(
  `/institutions-employees/${id}/decline`,
  payload,
)

export const approveInstitutionsEmployees = (id, payload) => apiAxios.post(
  `/institutions-employees/${id}/approve`,
  payload,
)

export const removeInstitutionsEmployees = (id, payload) => apiAxios.post(
  `/institutions-employees/${id}/remove`,
  payload,
)

export const fetchInstitutionEmployeeLastAsEmployee = (payload) => apiAxios.get(
  '/institutions-employees/last-as-employee',
  { params: payload },
)

export const featureInstitutionsEmployees = (id, payload) => apiAxios.post(
  `/institutions-employees/${id}/feature`,
  payload,
)

// ---------- RESERVATIONS ----------

export const fetchReservations = (payload) => apiAxios.get(
  '/reservations',
  { params: payload },
)

export const fetchLastReservations = (payload) => apiAxios.get(
  '/reservations/last',
  { params: payload },
)

export const fetchReservation = (id, payload) => apiAxios.get(
  `/reservations/${id}`,
  { params: payload },
)

export const destroyReservation = (id) => apiAxios.delete(
  `/reservations/${id}`,
)

export const storeReservation = (payload) => apiAxios.post(
  '/reservations',
  payload,
)

export const updateReservation = (id, payload) => apiAxios.patch(
  `/reservations/${id}`,
  payload,
)

export const confirmReservation = (id, payload) => apiAxios.patch(
  `/reservations/${id}/confirm`,
  payload,
)

export const cancelReservation = (id, payload) => apiAxios.patch(
  `/reservations/${id}/cancel`,
  payload,
)

export const archiveReservation = (id, payload) => apiAxios.patch(
  `/reservations/${id}/archive`,
  payload,
)

// ---------- CURRENCIES ----------

export const fetchCurrencies = (payload) => apiAxios.get(
  '/currencies',
  { params: payload },
)

// ---------- LOCALES ----------

export const fetchStaticLocales = (payload) => apiAxios.get(
  '/locales/statics',
  { params: payload },
)

export const fetchDynamicLocales = (payload) => apiAxios.get(
  '/locales/dynamics',
  { params: payload },
)

// ---------- EASYTRANSAC ----------

export const createEasyTransacAccount = (payload) => apiAxios.post(
  '/easy-transac/account/create',
  payload,
)

// ---------- PAYMENT ----------

export const createCheckoutSession = (payload) => apiAxios.post(
  '/payment/checkout/session',
  payload,
)

// ---------- TAGS ----------

export const fetchTags = (payload) => apiAxios.get(
  '/tags',
  { params: payload },
)

// ---------- PAYMENTS ----------

export const fetchPayments = (payload) => apiAxios.get(
  '/payments',
  { params: payload },
)

export const fetchPaymentPdf = (id, payload) => apiAxiosBlob.get(
  `/payments/${id}`,
  { params: payload },
)

export const fetchLastPayments = () => apiAxios.get(
  '/payments/last',
)

export const thanksPayment = (id, payload) => apiAxios.patch(
  `/payments/${id}/thank`,
  payload,
)

export const receiptPayment = (id, payload) => apiAxiosBlob.get(
  `/payments/${id}/receipt`,
  { params: payload },
)

export const downloadPdfPayments = (payload) => apiAxiosBlob.get(
  '/payments/pdf',
  { params: payload },
)

// ---------- POSTS ----------

export const fetchPosts = (payload) => apiAxios.get(
  '/posts',
  { params: payload },
)

// ---------- POSTS CATEGORIES ----------

export const fetchPostCategories = (payload) => apiAxios.get(
  '/post-categories',
  { params: payload },
)

export const fetchPostCategory = (id, payload) => apiAxios.get(
  `/post-categories/${id}`,
  { params: payload },
)

// ---------- PRIVACY POLICY ----------

export const fetchLastPrivacyPolicy = () => apiAxios.get(
  '/privacy-policies/last',
)

// ---------- TERMS AND CONDITIONS ----------

export const fetchLastTermsCondition = () => apiAxios.get(
  '/terms-conditions/last',
)

// ---------- CHANGELOGS ----------

export const fetchChangelogs = (payload) => apiAxios.get(
  '/changelogs',
  { params: payload },
)

export const fetchChangelog = (id, payload) => apiAxios.get(
  `/changelogs/${id}`,
  { params: payload },
)

// ---------- INTERACTIONS ----------

export const fetchInteractions = (payload) => apiAxios.get(
  '/interactions',
  { params: payload },
)

// ---------- PARTNERS ----------

export const fetchPartners = (payload) => apiAxios.get(
  '/partners',
  { params: payload },
)

export const fetchPartner = (id, payload) => apiAxios.get(
  `/partners/${id}`,
  { params: payload },
)

// ---------- PLANS ----------

export const fetchPlans = (payload) => apiAxios.get(
  '/plans',
  { params: payload },
)

export const subscribePlan = (id, payload) => apiAxios.post(
  `/plans/${id}/subscribe`,
  payload,
)

// ---------- SUBSCRIPTIONS ----------

export const paySubscription = (id, payload) => apiAxios.post(
  `/subscriptions/${id}/pay`,
  payload,
)

export const cancelSubscription = (id, payload) => apiAxios.post(
  `/subscriptions/${id}/cancel`,
  payload,
)

// ---------- ROLES ----------

export const fetchExtranetRegistrationRoles = (payload) => apiAxios.get(
  '/roles/extranet-registration',
  { params: payload },
)

// ---------- PDF ----------

export const downloadPdfDisplayA6 = (payload) => apiAxiosBlob.get(
  '/pdf/display-a6',
  { params: payload },
)

// ---------- SUPPORT REQUESTS ----------

export const fetchSupportRequests = (payload) => apiAxios.get(
  '/support-requests',
  { params: payload },
)

export const fetchSupportRequest = (id, payload) => apiAxios.get(
  `/support-requests/${id}`,
  { params: payload },
)

export const storeSupportRequest = (payload) => apiAxios.post(
  '/support-requests',
  payload,
)

export const cancelSupportRequest = (id, payload) => apiAxios.patch(
  `/support-requests/${id}/cancel`,
  { params: payload },
)

// ---------- SUPPORT CATEGORIES ----------

export const fetchSupportCategories = (payload) => apiAxios.get(
  '/support-categories',
  { params: payload },
)

// ---------- SUPPORT MESSAGES ----------

export const storeSupportMessage = (id, payload) => apiAxios.post(
  `/support-requests/${id}`,
  payload,
)

// ---------- CLAIMS ----------

export const fetchClaims = (payload) => apiAxios.get(
  '/claims',
  { params: payload },
)

export const fetchLastClaims = (payload) => apiAxios.get(
  '/claims/last',
  { params: payload },
)

export const fetchClaim = (id, payload) => apiAxios.get(
  `/claims/${id}`,
  { params: payload },
)

export const showClaimFile = (id, payload) => apiAxios.get(
  `/claims/${id}/s3-show-file`,
  { params: payload },
)

// ---------- TRAVELLER EMAILS ----------

export const fetchTravellerEmails = (payload) => apiAxios.get(
  '/traveller-emails',
  { params: payload },
)

export const exportTravellerEmails = (payload) => apiAxiosBlob.get(
  '/traveller-emails/csv',
  { params: payload },
)

// ---------- BANK TRANSFERS INSTITUTIONS ----------

export const fetchBankTransfersInstitutions = (payload) => apiAxios.get(
  '/bank_transfers_institutions',
  { params: payload },
)

// ---------- USER BANK INFORMATION ----------

export const updateUserBank = (payload) => apiAxios.patch(
  '/auth/user/bank',
  payload,
)

// ---------- BROADCAST PAGES ----------

export const fetchPublicBroadcastPage = (uuid) => apiAxios.get(
  `/broadcast-pages/public/${uuid}`,
)

export const fetchApiBroadcastPage = (id, payload) => apiAxios.get(
  `/broadcast-pages/${id}`,
  { params: payload },
)

export const updateBroadcastPage = (id, payload) => apiAxios.patch(
  `/broadcast-pages/${id}`,
  payload,
)

export const createBroadcastPage = (payload) => apiAxios.post(
  '/broadcast-pages',
  payload,
)
